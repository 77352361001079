import NextLink from "next/link"

// @configs
import routes from "@configs/routes"

// mui
import { Box, Button, Typography } from "@mui/material"

export default function Page() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // minHeight: 'var(--viewportHeight)',
      minHeight: '100vh',
    }}>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="body1" align="center">
        Page not found
      </Typography>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 5,
      }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          component={NextLink}
          href={routes.PAGES.HOME}
        >
          Go to home
        </Button>
      </Box>
    </Box>
  )
}

// i18n
import { getStaticPropsI18n, MakeGetStaticProps } from "@core/i18n/pageService/getStaticPropsI18n"

export const getStaticProps = (props: MakeGetStaticProps) => {
  return getStaticPropsI18n(props);
};
